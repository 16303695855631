import React from 'react';
import Carousel from 'react-multi-carousel';
import { IHomeCategory } from '../../models';
import PrimaryButtonSmall from '../PrimaryButtonSmall';
import localizedRoute from '../../utils/localizedRoute';
import { useTranslation } from 'react-i18next';

const MycarouselCategories = ({
  categories
}: {
  categories: IHomeCategory[] | undefined;
}) => {
  const { t } = useTranslation();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 20
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      partialVisibilityGutter: 20
    }
  };

  return (
    <>
      <h2
        className="text-5xl md:text-40 leading-tight font-sans mb-2 text-black"
        dangerouslySetInnerHTML={{ __html: t('FIRST_CAROUSEL_TITLE') }}
      />
      <p
        className="text-md uppercase font-sans mb-6 opacity-60"
        dangerouslySetInnerHTML={{ __html: t('FIRST_CAROUSEL_DESCRIPTION') }}
      />
      <Carousel
        ssr
        partialVisible
        responsive={responsive}
        swipeable={true}
        draggable={true}
        showDots={false}
        containerClass="carousel-container sport-carousel"
        infinite={true}
        arrows={true}
      >
        {categories &&
          categories.map(category => {
            return (
              <div
                className="relative px-2 h-500 mr-2"
                key={category.image}
                style={{
                  background: `linear-gradient(to bottom, rgba(0, 20, 26, 0.1), rgba(0, 20, 26, 0.15)), url(" ${category.image} ")`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat'
                }}
              >
                <div className="absolute bottom-6 left-6">
                  <p
                    className="text-4xl text-white mb-2 font-bold font-sans"
                    dangerouslySetInnerHTML={{
                      __html: category.title.includes('|||')
                        ? category.title.replaceAll('|||', '<br />')
                        : category.title
                    }}
                  />
                  <p>
                    <span className="mr-2">
                      <PrimaryButtonSmall
                        text={category.btn_text}
                        href={localizedRoute(category.btn_link)}
                      />
                    </span>
                  </p>
                </div>
              </div>
            );
          })}
      </Carousel>
    </>
  );
};

export default MycarouselCategories;
